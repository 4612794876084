import React from "react"

export default function FooterLayout({ children }) {
  return (
      <div style={{ margin: `0 auto`, maxWidth: 750, padding: `0 1rem 1rem 0` }}>
            {children}
            <div class="footer">
                <a href="/">Home</a>
                <a href="/terms">Terms and Conditions</a>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/contact">Contact</a>
                <p>© 2021  Christos Karaiskos – All rights reserved</p>
            </div>
      </div>
  )
}