import React from "react"
import geoLogo from '../resources/geoLogo.svg'

export default function Menu({ children }) {
  return (
      <div>
        <a href="/" style={{textDecoration: "none"}}>
          <div>
            <img class="geo-logo-small" src={geoLogo} alt="Geognosis logo" />
            <p class="geo-back">Geognosis</p>
          </div>
        </a>
        {children}
      </div>
  )
}