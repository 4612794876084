import * as React from "react"

import FooterLayout from "../components/footer"
import Menu from "../components/home"

export default function Privacy() {
    return (
        <Menu>
            <FooterLayout>
            <h1>Privacy Policy</h1>
            <div>
                <div>
                    <h3>1. Introduction</h3>
                    <p>Hello, and welcome to the Geognosis Privacy Policy.</p>
                    <p>Thanks for choosing Geognosis App, an educational geography app created by Christos Karaiskos ("me", "Geognosis", "we", "us", "our"). Use of the Geognosis App and any of its content, services and features (collectively "Services"), as defined below, is subject to your acceptance of these terms.</p>
                </div>
                <div>
                    <h3>2. Changes to the Privacy Policy</h3>
                    <p>We review this Privacy Policy regularly and reserve the right to make changes at any time to take account of changes in our business activities, legal requirements, and the manner in which we process personal data.</p>
                    <p>Should we do so, we will notify you by various procedures through the App (for example, through a banner, a pop-up or a push notification), or we may even send you a notice to your e-mail address.</p>
                    <p>In any case, we suggest you to review this Privacy Policy from time to time in case minor changes are made or we make any interactive improvement, taking the opportunity that you will always find it as a permanent point of information on our Website and our App.</p>
                </div>
                <div>
                    <h3>3. Personal and Non-personal Data</h3>
                    <p>When you contact us for technical support or feature suggestions through email, you will need to provide your personal data, such as name and email address. The personal data will be processed by us to respond to such requests or contact you solely for the purpose of feedback on the app if your consent is given. The Geognosis app does not ask any personal information or store any information about you on our servers.</p>
                </div>
                <div>
                    <h3>4. Third-party Services</h3>
                    <p>Our Service makes use of third-party services, which use your personal data in their own ways and have their own privacy policies. More specifically we use:</p>
                    <ul>
                        <li>MapBox SDK for showing the map in the "Find the Country" quiz. See <a href='https://www.mapbox.com/privacy/'>https://www.mapbox.com/privacy/</a></li>
                        <li>Firebase SDK for anonymous usage analytics and crash reports. See <a href='https://firebase.google.com/policies/analytics'> https://firebase.google.com/policies/analytics</a></li>
                        <li>AdMob SDK for serving personalized advertisements. See <a href='https://support.google.com/admob/answer/6128543'>https://support.google.com/admob/answer/6128543</a> .You can always disable tracking by resetting your Advertizing Identifier or enabling the "Limit Ad Tracking" toggle in iPhone Settings.​</li>
                    </ul>
                </div>
                <div>
                    <h3>5. Contact</h3>
                    <p>If you have any questions about this Privacy Policy, please <a href="/contact">contact us</a>.​</p>
                </div>
            </div>
        </FooterLayout>
     </Menu>

    );
  }